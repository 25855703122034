import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { iconsMap, renderIcon } from "./shared/icons";
import { FaCheckCircle } from "react-icons/fa";
import { Fade } from "react-awesome-reveal";

const Price = ({ props, icon, title, features, selected }) => {
  return (
    <Card class="card selected">
      <Card.Body>
        <Card.Title>
          <div
            className={
              "princing-header d-flex text-wrap flex-column align-content-center"
            }
          >
            {renderIcon(iconsMap.get(icon))}
            {title}
          </div>
        </Card.Title>
        <div className="features">
          <ul>
            {features.map((feature, index) => (
              <li key={index}>
                <FaCheckCircle className={"check"} />
                {feature}
              </li>
            ))}
          </ul>
        </div>
      </Card.Body>
    </Card>
  );
};

export const Prices = (props) => {
  return (
    <div className="position-relative">
      <div className="divider divider-top-1"></div>
      <section id="prices" className="text-center">
        <div className="background">
          <Container>
            <Row className="justify-content-center section-title">
              <h2>{props.data ? props.data.title : "Loading"}</h2>
            </Row>
            <Row className={"justify-content-center mb-5 mt-3"}>
              {props.data
                ? props.data.list.map((d, i) => (
                    <Col
                      key={`${d.title}-${i}`}
                      xs={12}
                      md={4}
                      className={"mt-3 d-flex justify-content-center"}
                    >
                      <Fade
                        direction={"up"}
                        delay={i * 400}
                        triggerOnce={true}
                        className="price-card"
                      >
                        <Price
                          props={props}
                          icon={d.icon}
                          title={d.title}
                          features={d.features}
                          price={d.price}
                        />
                      </Fade>
                    </Col>
                  ))
                : "Loading..."}
            </Row>
            <Row className="section-subtitle mb-5">
              <h3>{props.data ? props.data.information : "Loading"}</h3>
            </Row>
          </Container>
        </div>
      </section>
      <div className="divider divider-bottom-2"></div>
    </div>
  );
};
